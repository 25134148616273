import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 5×2\\@90% 1RM`}</p>
    <p>{`Glute Ham Raise 5×4 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`5-Front Squats (95/65)`}</p>
    <p>{`5-Pullups`}</p>
    <p>{`5-Lateral Burpees Over Bar`}</p>
    <p>{`5-T2B`}</p>
    <p><em parentName="p">{`*`}{`reps increase by 5 each round.  Score is total reps.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Reminder for those who have registered for the CrossFit Open: Your
score must be submitted by this evening at 8:00pm!  If you did 20.1 on
Friday and would still like to register you can do so until Monday at
8:00pm.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      